import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { getChartOptions } from './utils/getChartOptions';

export function ComparisonChart({
  data,
  chartComponent,
  labelReplacer,
  metricFilter,
  chartType,
  size,
  sortByValue,
  topLimit,
  visibleMetricsData,
  chartReducedSpace,
  colors,
  onDataClick,
}) {
  if (!data || (!data.length && !data[0]?.guide)) {
    return null;
  }

  const categories = data.map((item) => {
    return {
      label: item.guide['Metric Name'].replace(labelReplacer, ''),
    };
  });

  // Extract colors from the data if they exist
  const chartColors = data.map((item) => item.guide.Color).filter(Boolean);

  const { guide } = data[0];

  const isPercentage = data.every((item) => item.guide['Value Type'] === 'Percentage');
  const percentageData = data.filter((item) => item.guide['Value Type'] === 'Percentage');

  const chartOptions = getChartOptions({
    customColors: chartColors.length > 0 ? chartColors : colors,
    categories,
    values: data.map((item) => (isPercentage ? 100 * Number(item.value) : Number(item.value))),
    guide,
    chartType,
    size,
    sortByValue,
    topLimit,
    visibleMetricsData,
    chartReducedSpace,
    isPercentage: isPercentage,
    onDataClick,
  });

  return (
    <HighchartsReact
      data-testid="chart"
      ref={chartComponent}
      highcharts={Highcharts}
      options={chartOptions}
    />
  );
}
