import React, { useEffect, useState, useContext, useRef } from 'react';
import { Stack, Grid, Box, Collapse, Container, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import api, { Video } from 'api';
import { FiltersContext } from 'context/FiltersContext';
import { DividedStats } from '../StatPanel/DividedStats';
import ChartAndStatsCell from './ChartAndStatsCell';
import InfographicAndStatsCell from './InfographicAndStatsCell';
import CircleAndStatsCell from './CircleAndStatsCell';
import {
  transformPercentageMetricsData,
  transformAndCombineRiskData,
  transformCountMetricsData,
} from './utils/StatsTransformers';
import Gallery from './Gallery';

const VIDEOS_PER_PAGE = 50;

const BatchGallery = () => {
  const { batchId } = useParams();
  const [videos, setVideos] = useState<Video[]>([]);
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const [isStatsLoading, setIsStatsLoading] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const [paginationKey, setPaginationKey] = useState(null);
  const location = useLocation();
  const fromPage = location.state?.from || '/';
  const filtersUpdated = location.state?.filtersUpdated || false;
  const [hasMore, setHasMore] = useState(true);

  // Batch data
  const [metricsVisible, setMetricsVisible] = useState(true);
  const [contentCount, setContentCount] = useState(null);
  const [uniquePublisherCount, setUniquePublisherCount] = useState(null);
  const [sentimentPieData, setSentimentPieData] = useState(null);
  const [riskPieData, setRiskPieData] = useState(null);
  const [emotionPieData, setEmotionPieData] = useState(null);
  const [garmPieData, setGarmPieData] = useState(null);

  const [emotionStats, setEmotionStats] = useState([]);
  const [totalEmotionCount, setTotalEmotionCount] = useState(0);
  const [sentimentStats, setSentimentStats] = useState([]);
  const [sentimentDescription, setSentimentDescription] = useState<React.ReactElement>(null);
  const [sentimentColors, setSentimentColors] = useState({
    Positive: '#3592fb',
    Negative: '#ed68a9',
    Neutral: '#54e1c6',
    Disingenuous: '#ffffff', //TODO: can remove once disingenuous sentiment is removed
    'No Content': '#a8a8a5',
  });
  const [riskColors, setRiskColors] = useState({
    High: '#f05e48',
    Medium: '#f3df4a',
    Low: '#6FCE3C',
    'No Content': '#a8a8a5',
  });
  const [genreStats, setGenreStats] = useState([]);
  const [totalGenreCount, setTotalGenreCount] = useState(0);
  const [genreDescription, setGenreDescription] = useState('');
  const [totalToneCount, setTotalToneCount] = useState(0);
  const [toneStats, setToneStats] = useState([]);
  const [tonePieData, setTonePieData] = useState([]);
  const [genrePieData, setGenrePieData] = useState([]);
  const [themePieData, setThemePieData] = useState([]);
  const [themeStats, setThemeStats] = useState([]);
  const [totalThemeCount, setTotalThemeCount] = useState(0);
  const [garmStats, setGarmStats] = useState([]);
  const [totalGarmCount, setTotalGarmCount] = useState(0);
  const [riskStats, setRiskStats] = useState([]);
  const [riskDescription, setRiskDescription] = useState<React.ReactElement>(null);

  const [haveStats, setHaveStats] = useState(false);

  const { setRiskFilter, setEmotionFilter, setGenreFilter, setSentimentFilter } = useContext(FiltersContext);

  const {
    sourceFilters,
    garmRiskLevelFilters,
    sentimentFilters,
    emotionFilters,
    genreFilters,
    setGarmRiskLevelFilters,
    setSentimentFilters,
    setEmotionFilters,
    setGenreFilters,
    lastPage,
    setRedirection,
  } = useContext(FiltersContext);

  useEffect(() => {
    setRedirection(location.pathname);
  }, []);

  const filtersInitializedRef = useRef(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const fetchBatchVideos = async (paginationKey = null) => {
    setIsVideoLoading(true);
    try {
      const recentVideosResponse = await api.mbs.getBatchVideos(
        batchId,
        paginationKey,
        VIDEOS_PER_PAGE,
        sentimentFilters,
        garmRiskLevelFilters,
        sourceFilters,
        emotionFilters,
        genreFilters,
      );
      const newVideos = recentVideosResponse.body?.videos || [];
      setVideos((prevVideos) => {
        const videoSet = new Set(prevVideos.map((video) => video.video_id));
        const uniqueNewVideos = newVideos.filter((video) => !videoSet.has(video.video_id));
        return [...prevVideos, ...uniqueNewVideos];
      });
      setPaginationKey(recentVideosResponse.body.pagination_key);
      setHasMore(recentVideosResponse.body.pagination_key !== null);
    } catch (error) {
      console.error('Failed to fetch videos:', error);
    } finally {
      setIsVideoLoading(false);
    }
  };

  useEffect(() => {
    setVideos([]);
    fetchBatchVideos();
  }, [sourceFilters, garmRiskLevelFilters, sentimentFilters, emotionFilters, genreFilters]);

  const fetchBatchStats = async (batchId) => {
    setIsStatsLoading(true);
    const batchStatsResponse = await api.mbs.getBatchStatistics(batchId);
    console.log(batchStatsResponse.body);

    const totalItems = batchStatsResponse.body?.statistics?.total_items;
    setContentCount(totalItems);
    setUniquePublisherCount(batchStatsResponse.body?.statistics?.unique_channel_count);

    if (totalItems !== null && totalItems > 0) {
      const sentimentData = batchStatsResponse.body?.statistics?.sentiment?.data;
      const sentimentLabels = batchStatsResponse.body?.statistics?.sentiment?.labels;

      if (sentimentData && sentimentLabels) {
        const transformedSentimentData = transformPercentageMetricsData(
          sentimentData,
          sentimentLabels,
          totalItems,
          sentimentColors,
        );
        console.log('Transformed Sentiment Data:', transformedSentimentData);
        setSentimentPieData(transformedSentimentData);
        setSentimentStats(
          createStatsArray(sentimentLabels, sentimentData, 'Sentiment Description'),
        );

        // Determine the sentiment with the highest value
        const highestSentimentIndex = sentimentData.indexOf(Math.max(...sentimentData));
        const highestSentimentLabel = sentimentLabels[highestSentimentIndex];
        const highestSentimentPercentage = (
          (sentimentData[highestSentimentIndex] / totalItems) *
          100
        ).toFixed(2);

        const sentimentDescription = getSentimentDescription(
          highestSentimentLabel,
          highestSentimentPercentage,
        );
        setSentimentDescription(sentimentDescription);
      } else {
        console.error('Sentiment data or labels are missing:', { sentimentData, sentimentLabels });
      }

      const riskData = batchStatsResponse.body?.statistics?.risk_level?.data;
      const riskLabels = batchStatsResponse.body?.statistics?.risk_level?.labels;

      if (riskData && riskLabels) {
        const { data: updatedRiskData, labels: updatedRiskLabels } = transformAndCombineRiskData(
          riskData,
          riskLabels,
        );
        console.log('Updated Risk Data:', updatedRiskData);
        console.log('Updated Risk Labels:', updatedRiskLabels);

        const transformedRiskPieData = transformPercentageMetricsData(
          updatedRiskData,
          updatedRiskLabels,
          totalItems,
          riskColors,
        );
        console.log('Transformed Risk Pie Data:', transformedRiskPieData);

        setRiskPieData(transformedRiskPieData);
        setRiskStats(createStatsArray(updatedRiskLabels, updatedRiskData, 'Risk Description'));
        // Determine the risk with the highest value
        const highestRiskIndex = riskData.indexOf(Math.max(...riskData));
        const highestRiskLabel = riskLabels[highestRiskIndex];
        const highestRiskPercentage = ((riskData[highestRiskIndex] / totalItems) * 100).toFixed(2);

        const riskDescription = getRiskDescription(highestRiskLabel, highestRiskPercentage);
        setRiskDescription(riskDescription);
      } else {
        console.error('Risk data or labels are missing:', { riskData, riskLabels });
      }
      //emotion
      const emotionData = batchStatsResponse.body?.statistics?.emotion?.data;
      const emotionLabels = batchStatsResponse.body?.statistics?.emotion?.labels;
      const combinedEmotionData = emotionData?.map((value, index) => ({
        label: emotionLabels[index],
        value: value,
      })) || [];

      // Sort the combined array by value in descending order
      combinedEmotionData.sort((a, b) => b.value - a.value);

      // Separate the sorted data back into emotionData and emotionLabels
      const sortedEmotionData = combinedEmotionData.map((item) => item.value);
      const sortedEmotionLabels = combinedEmotionData.map((item) => item.label);
      const topCombinedEmotionData = combinedEmotionData.slice(0, 8);
      if (emotionData && emotionLabels) {
        const transformedEmotionData = transformCountMetricsData(
          topCombinedEmotionData.map((item) => item.value),
          topCombinedEmotionData.map((item) => item.label),
        );
        console.log('Transformed Emotion Data:', transformedEmotionData);
        setEmotionPieData(transformedEmotionData);

        const topEmotions = getTopNStats(emotionLabels, emotionData, 5);
        setEmotionStats(topEmotions);
        setTotalEmotionCount(emotionLabels.length); // Set the total unique emotion count
      } else {
        console.error('Emotion data or labels are missing');
      }
      //genre

      const genreData = batchStatsResponse.body?.statistics?.genre?.data;
      const genreLabels = batchStatsResponse.body?.statistics?.genre?.labels;
      const combinedGenreData = genreData.map((value, index) => ({
        label: genreLabels[index],
        value: value,
      }));

      combinedGenreData.sort((a, b) => b.value - a.value);

      const sortedGenreData = combinedGenreData.map((item) => item.value);
      const sortedGenreLabels = combinedGenreData.map((item) => item.label);
      const topCombinedGenreData = combinedGenreData.slice(0, 4);
      if (sortedGenreData && sortedGenreLabels) {
        const transformedGenreData = transformCountMetricsData(
          topCombinedGenreData.map((item) => item.value),
          topCombinedGenreData.map((item) => item.label),
        );
        setGenrePieData(transformedGenreData);

        const topGenre = getTopNStats(sortedGenreLabels, sortedGenreData, 5);
        setGenreStats(topGenre);
        setTotalGenreCount(sortedGenreLabels.length);
      } else {
        console.error('Emotion data or labels are missing');
      }

      const garmData = batchStatsResponse.body?.statistics?.garm_labels?.data;
      const garmLabels = batchStatsResponse.body?.statistics?.garm_labels?.labels;

      if (garmData && garmLabels) {
        const transformedGarmData = transformCountMetricsData(garmData, garmLabels);
        console.log('Transformed Garm Data:', transformedGarmData);
        setGarmPieData(transformedGarmData);

        const topGarmCategories = getTopNStats(garmLabels, garmData, 5);
        setGarmStats(topGarmCategories);
        setTotalGarmCount(garmLabels.length); // Set the total unique GARM category count
      } else {
        console.error('Garm data or labels are missing:', { garmData, garmLabels });
      }

      //tone

      const toneData = batchStatsResponse.body?.statistics?.tone?.data;
      const toneLabels = batchStatsResponse.body?.statistics?.tone?.labels;

      if (toneData && toneLabels) {
        const combinedToneData = toneData.map((value, index) => ({
          label: toneLabels[index],
          value: value,
        }));

        combinedToneData.sort((a, b) => b.value - a.value);
        const topCombinedToneData = combinedToneData.sort((a, b) => b.value - a.value).slice(0, 6);

        const transformedToneData = transformCountMetricsData(
          topCombinedToneData.map((item) => item.value),
          topCombinedToneData.map((item) => item.label),
        );
        setTonePieData(transformedToneData);
        setToneStats(topCombinedToneData);

        setTotalToneCount(toneLabels.length);
      } else {
        console.error('Emotion data or labels are missing');
      }

      //theme

      const themeData = batchStatsResponse.body?.statistics?.theme?.data;
      const themeLabels = batchStatsResponse.body?.statistics?.theme?.labels;
      const combinedThemeData = themeData.map((value, index) => ({
        label: themeLabels[index],
        value: value,
      }));

      combinedThemeData.sort((a, b) => b.value - a.value);

      const sortedThemeData = combinedThemeData.map((item) => item.value);
      const sortedThemeLabels = combinedThemeData.map((item) => item.label);
      const topCombinedThemeData = combinedThemeData.slice(0, 5);
      if (sortedThemeData && sortedThemeLabels) {
        const transformedThemeData = transformCountMetricsData(
          topCombinedThemeData.map((item) => item.value),
          topCombinedThemeData.map((item) => item.label),
        );
        // setThemePieData(transformedThemeData);
        setThemeStats(topCombinedThemeData);

        setTotalThemeCount(sortedThemeLabels.length);
      } else {
        console.error('Emotion data or labels are missing');
      }
    }
    setIsStatsLoading(false);
  };

  const getSentimentDescription = (label, percentage) => {
    percentage = parseFloat(percentage);
    if (percentage === 100) {
      return (
        <>
          <Box component="span" fontWeight="fontWeightBold">
            All
          </Box>{' '}
          of the content had {label.toLowerCase()} sentiment.
        </>
      );
    } else if (percentage > 95) {
      return (
        <>
          Nearly{' '}
          <Box component="span" fontWeight="fontWeightBold">
            all
          </Box>{' '}
          of the content had {label.toLowerCase()} sentiment.
        </>
      );
    } else if (percentage > 75) {
      return (
        <>
          More than{' '}
          <Box component="span" fontWeight="fontWeightBold">
            two thirds
          </Box>{' '}
          of the content had {label.toLowerCase()} sentiment.
        </>
      );
    } else if (percentage > 50) {
      return (
        <>
          Over{' '}
          <Box component="span" fontWeight="fontWeightBold">
            half
          </Box>{' '}
          of the content had {label.toLowerCase()} sentiment.
        </>
      );
    } else {
      return (
        <>
          <Box component="span" fontWeight="fontWeightBold">
            {percentage}%
          </Box>{' '}
          of the content had {label.toLowerCase()} sentiment.
        </>
      );
    }
  };

  const getRiskDescription = (label, percentage) => {
    percentage = parseFloat(percentage);
    if (percentage === 100) {
      return (
        <>
          <Box component="span" fontWeight="fontWeightBold">
            All
          </Box>{' '}
          of the content had a {label.toLowerCase()} risk level.
        </>
      );
    } else if (percentage > 95) {
      return (
        <>
          Nearly{' '}
          <Box component="span" fontWeight="fontWeightBold">
            all
          </Box>{' '}
          of the content had a {label.toLowerCase()} risk level.
        </>
      );
    } else if (percentage > 75) {
      return (
        <>
          More than{' '}
          <Box component="span" fontWeight="fontWeightBold">
            two thirds
          </Box>{' '}
          of the content had a {label.toLowerCase()} risk level.
        </>
      );
    } else if (percentage > 50) {
      return (
        <>
          Over{' '}
          <Box component="span" fontWeight="fontWeightBold">
            half
          </Box>{' '}
          of the content had a {label.toLowerCase()} risk level.
        </>
      );
    } else {
      return (
        <>
          <Box component="span" fontWeight="fontWeightBold">
            {percentage}%
          </Box>{' '}
          of the content had a {label.toLowerCase()} risk level.
        </>
      );
    }
  };
  const getTopNStats = (labels, data, n) => {
    const statsArray = labels.map((label, index) => ({
      label,
      value: data[index],
    }));

    statsArray.sort((a, b) => b.value - a.value);

    return statsArray.slice(0, n);
  };
  const createStatsArray = (labels, data, description) => {
    return labels.map((label, index) => ({
      label,
      value: data[index],
      description,
    }));
  };
  useEffect(() => {
    if (!haveStats) {
      fetchBatchStats(batchId);
      setHaveStats(true);
    }
  }, [batchId]);

  useEffect(() => {
    console.log('Redirect to from', fromPage, filtersUpdated);
    if (!filtersInitializedRef.current && !fromPage.startsWith('/content')) {
      console.log('clearing out filters');

      setGarmRiskLevelFilters([]);
      setSentimentFilters([]);
      setEmotionFilters([]);
      setGenreFilters([]);
      filtersInitializedRef.current = true;
    }
    if (filtersUpdated) {
      console.log('Filters updated, fetching new videos');
      setVideos([]);
      fetchBatchVideos();
    }
  }, [setGarmRiskLevelFilters, setSentimentFilters, setEmotionFilters, setGenreFilters]);

  const handleThumbnailClick = (content) => {
    if (content.source === 'youtube') {
      navigate(
        `/content/${encodeURIComponent('https://www.youtube.com/watch?v=' + content.video_id)}`,
        {
          state: {
            content,
            creatorName: content.channel_name,
            contentTitle: content.video_title,
            from: `/batch/${batchId}`,
          },
        },
      );
    } else if (content.source === 'tiktok') {
      navigate(`/content/${encodeURIComponent(content.video_url)}`, {
        state: {
          content,
          creatorName: content.channel_name,
          contentTitle: content.video_title,
          from: `/batch/${batchId}`,
        },
      });
    } else if (content.source === 'xsocial') {
      navigate(`/content/${encodeURIComponent(content.post_url)}`, {
        state: {
          content,
          creatorName: content.channel_name,
          contentTitle: content.channel_nickname,
          from: `/batch/${batchId}`,
        },
      });
    } else if (content.source === 'article') {
      navigate(`/content/${encodeURIComponent(content.video_id)}`, {
        state: {
          content,
          creatorName: content.channel_id,
          contentTitle: content.article_title,
          from: `/batch/${batchId}`,
        },
      });
    } else if (content.source === 'iris_tv') {
      navigate(`/content/${encodeURIComponent(content.video_id)}`, {
        state: { content, contentTitle: content.video_title, from: `/batch/${batchId}`, },
      });
    } else if (content.source === 'cloud_file_storage') {
      navigate(`/content/${content.video_id}`, {
        state: { content, contentTitle: content.video_title, from: `/batch/${batchId}`, },
      });

    }
  };
  const generateThumbnailNavigationObject = (content) => {
    if (content.source === 'youtube') {
      return {
        pathname: `/content/${encodeURIComponent('https://www.youtube.com/watch?v=' + content.video_id)}`,
        state: {
          content,
          creatorName: content.channel_name,
          contentTitle: content.video_title,
          from: `/batch/${batchId}`,
        },
      };
    } else if (content.source === 'tiktok') {
      return {
        pathname: `/content/${encodeURIComponent(content.video_url)}`,
        state: {
          content,
          creatorName: content.channel_name,
          contentTitle: content.video_title,
          from: `/batch/${batchId}`,
        },
      };
    } else if (content.source === 'xsocial') {
      return {
        pathname: `/content/${encodeURIComponent(content.post_url)}`,
        state: {
          content,
          creatorName: content.channel_name,
          contentTitle: content.channel_nickname,
          from: `/batch/${batchId}`,
        },
      };
    } else if (content.source === 'article') {
      return {
        pathname: `/content/${encodeURIComponent(content.video_id)}`,
        state: {
          content,
          creatorName: content.channel_id,
          contentTitle: content.article_title,
          from: `/batch/${batchId}`,
        },
      };
    } else if (content.source === 'iris_tv') {
      return {
        pathname: `/content/${encodeURIComponent(content.video_id)}`,
        state: { content, contentTitle: content.video_title, from: `/batch/${batchId}` },
      };
    } else if (content.source === 'cloud_file_storage') {
      return {
        pathname: `/content/${content.video_id}`,
        state: { content, contentTitle: content.video_title, from: `/batch/${batchId}` },
      };
    }
  };
  const toggleMetricsVisibility = () => {
    setMetricsVisible(!metricsVisible);
  };
  console.log('Theme stats', themeStats);

  useEffect(() => {
    if (isInitialLoad) {
      setIsInitialLoad(isStatsLoading || isVideoLoading);
    }
  }, [isStatsLoading, isVideoLoading]);

  function formatNumberWithCommas(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function scrollToGallery() {
    const galleryContainer = document.getElementById('gallery-container');
    galleryContainer?.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <Stack
      spacing={4}
      style={{
        padding: '10px',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
      }}
    >
      <Stack
        sx={{
          // marginRight: '5px',
          // marginLeft: '5px',
          maxWidth: { xs: '90%', md: '100%', lg: '80%' },
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <>
          {contentCount !== null && uniquePublisherCount && (
            <Container style={{ maxWidth: '120%' }}>
              <DividedStats
                stats={[
                  { value: formatNumberWithCommas(contentCount), label: 'Pieces of Content' },
                  { value: formatNumberWithCommas(uniquePublisherCount), label: 'Unique Placements' },
                ]}
              />
              <Grid container spacing={0}>
                {/* <StatSectionHeader
                  title="Metrics"
                  onClick={toggleMetricsVisibility}
                  style={{ flexGrow: 1 }}
                /> */}
                <Grid item xs={12} style={{ marginRight: '20px', marginLeft: '-3px' }}>
                  <Collapse in={metricsVisible}>
                    <Grid
                      container
                      spacing={isSmallScreen ? 1 : 0}
                      style={{
                        flexGrow: 1,
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}
                      >
                        <ChartAndStatsCell
                          title="Risk"
                          chartData={{
                            data: riskPieData,
                            chartType: 'pie',
                          }}
                          totalCount={0}
                          contentCount={contentCount}
                          description={riskDescription}
                          orientation="vertical"
                          sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}
                          onDataClick={(value) => {
                            setRiskFilter(value);
                            scrollToGallery();
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}
                      >
                        <CircleAndStatsCell
                          title="Theme"
                          topStats={themeStats}
                          totalCount={formatNumberWithCommas(totalThemeCount)}
                          sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}
                      >
                        <ChartAndStatsCell
                          title="Emotion"
                          chartData={{
                            data: emotionPieData,
                            chartType: 'bar',
                          }}
                          totalCount={formatNumberWithCommas(totalEmotionCount)}
                          contentCount={contentCount}
                          orientation="vertical"
                          sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}
                          onDataClick={(value) => {
                            setEmotionFilter(value);
                            scrollToGallery();
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}
                      >
                        <ChartAndStatsCell
                          title="Genre"
                          chartData={{
                            data: genrePieData,
                            chartType: 'column',
                          }}
                          totalCount={formatNumberWithCommas(totalGenreCount)}
                          contentCount={contentCount}
                          descriptionPosition="top"
                          orientation="vertical"
                          titlePosition="bottom"
                          sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}
                          onDataClick={(value) => {
                            setGenreFilter(value);
                            scrollToGallery();
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}
                      >
                        <ChartAndStatsCell
                          title="Sentiment"
                          chartData={{
                            data: sentimentPieData,
                            chartType: 'pie',
                          }}
                          topStats={sentimentStats}
                          totalCount={0}
                          contentCount={contentCount}
                          description={sentimentDescription}
                          orientation="vertical"
                          titlePosition="bottom"
                          descriptionPosition="top"
                          sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}
                          onDataClick={(value) => {
                            setSentimentFilter(value);
                            scrollToGallery();
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}
                      >
                        <InfographicAndStatsCell
                          title="Tone"
                          topStats={toneStats}
                          totalCount={formatNumberWithCommas(totalToneCount)}
                          // description={toneDescription}

                          contentCount={contentCount}
                          orientation="vertical"
                          descriptionPosition="top"
                          titlePosition="bottom"
                          sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}
                        />
                      </Grid>
                    </Grid>
                  </Collapse>
                </Grid>
              </Grid>
            </Container>
          )}
        </>
      </Stack>
      <Gallery
        videos={videos}
        hasMore={hasMore}
        isLoading={isStatsLoading || isVideoLoading}
        isInitialLoading={isInitialLoad}
        paginationKey={paginationKey}
        fetchVideos={fetchBatchVideos}
        handleThumbnailClick={handleThumbnailClick}
        generateThumbnailNavigationObject={generateThumbnailNavigationObject}
      />
    </Stack>
  );
};

export default BatchGallery;
