import React, { createContext, useState, ReactNode } from 'react';

import {
  garmRiskOptions,
  sentimentOptions,
  emotionOptions,
  genreOptions,
} from '../Helpers/FilterOptions';

export interface Filter {
  label: string;
  value: string;
}

interface FiltersContextType {
  filters: FilterOption[];
  lastPage: string;
  sourceFilters: Filter[];
  garmRiskLevelFilters: Filter[];
  garmRiskLevelFiltersDisplay: Filter[];
  sentimentFilters: Filter[];
  sentimentFiltersDisplay: Filter[];
  emotionFilters: Filter[];
  genreFilters: Filter[];
  clearFilters: () => void;
  setLastPage: React.Dispatch<React.SetStateAction<string>>;
  setSourceFilters: React.Dispatch<React.SetStateAction<Filter[]>>;
  setGarmRiskLevelFilters: React.Dispatch<React.SetStateAction<Filter[]>>;
  setGarmRiskLevelFiltersDisplay: React.Dispatch<React.SetStateAction<Filter[]>>;
  setSentimentFilters: React.Dispatch<React.SetStateAction<Filter[]>>;
  setSentimentFiltersDisplay: React.Dispatch<React.SetStateAction<Filter[]>>;
  setEmotionFilters: React.Dispatch<React.SetStateAction<Filter[]>>;
  setGenreFilters: React.Dispatch<React.SetStateAction<Filter[]>>;
  setRedirection: (fromPage: string | undefined) => void;

  setRiskFilter: (value: string) => void;
  setSentimentFilter: (value: string) => void;
  setEmotionFilter: (value: string) => void;
  setGenreFilter: (value: string) => void;
}

const DEFAULT_FILTERS = {
  filters: [],
  lastPage: '/',
  sourceFilters: [],
  garmRiskLevelFilters: [],
  garmRiskLevelFiltersDisplay: [],
  sentimentFilters: [],
  sentimentFiltersDisplay: [],
  emotionFilters: [],
  genreFilters: [],
  clearFilters: () => {},
  setLastPage: () => {},
  setSourceFilters: () => {},
  setGarmRiskLevelFilters: () => {},
  setGarmRiskLevelFiltersDisplay: () => {},
  setSentimentFilters: () => {},
  setSentimentFiltersDisplay: () => {},
  setEmotionFilters: () => {},
  setGenreFilters: () => {},
  setRedirection: () => {},

  setRiskFilter: (value: string) => {},
  setSentimentFilter: (value: string) => {},
  setEmotionFilter: (value: string) => {},
  setGenreFilter: (value: string) => {},
};

export const FiltersContext = createContext<FiltersContextType>(DEFAULT_FILTERS);

export type FilterOption = {
  title: string;
  options: Filter[];
  values: Filter[];
  setter: (value: Filter[]) => void;
  displaySetter?: ((value: Filter[]) => void);
};
interface FiltersProviderProps {
  children: ReactNode;
}

export const FiltersProvider: React.FC<FiltersProviderProps> = ({ children }) => {
  const [sourceFilters, setSourceFilters] = useState<Filter[]>([]);
  const [garmRiskLevelFilters, setGarmRiskLevelFilters] = useState<Filter[]>([]);
  const [garmRiskLevelFiltersDisplay, setGarmRiskLevelFiltersDisplay] = useState<Filter[]>([]);
  const [sentimentFilters, setSentimentFilters] = useState<Filter[]>([]);
  const [sentimentFiltersDisplay, setSentimentFiltersDisplay] = useState<Filter[]>([]);
  const [emotionFilters, setEmotionFilters] = useState<Filter[]>([]);
  const [genreFilters, setGenreFilters] = useState<Filter[]>([]);
  const [lastPage, setLastPage] = useState<string>('/');

  const filters: FilterOption[] = [
    {
      title: 'Risk',
      options: garmRiskOptions,
      values: garmRiskLevelFiltersDisplay,
      setter: setGarmRiskLevelFilters,
      displaySetter: setGarmRiskLevelFiltersDisplay,
    },
    {
      title: 'Sentiment',
      options: sentimentOptions,
      values: sentimentFiltersDisplay,
      setter: setSentimentFilters,
      displaySetter: setSentimentFiltersDisplay,
    },
    {
      title: 'Emotion',
      options: emotionOptions,
      values: emotionFilters,
      setter: setEmotionFilters,
    },
    {
      title: 'Genre',
      options: genreOptions,
      values: genreFilters,
      setter: setGenreFilters,
    },
  ];

  const makeSetter = (filter: 'Risk' | 'Sentiment' | 'Emotion' | 'Genre') => (valueAsString: string) => {
    const filterObj = filters.find((f) => f.title === filter);
    if (!filterObj) return () => {};
    clearFilters();
    const existingValue = filterObj.options.find((o) => o.label === valueAsString);
    const value = [existingValue || { label: valueAsString, value: valueAsString.toLowerCase() }];
    filterObj.setter(value);
    if (filterObj.displaySetter) {
      filterObj.displaySetter(value);
    }
  };
  
  const setRiskFilter = makeSetter('Risk');
  const setSentimentFilter = makeSetter('Sentiment');
  const setEmotionFilter = makeSetter('Emotion');
  const setGenreFilter = makeSetter('Genre');

  const clearFilters = () => {
    setGarmRiskLevelFilters([]);
    setGarmRiskLevelFiltersDisplay([]);
    setSentimentFilters([]);
    setSentimentFiltersDisplay([]);
    setEmotionFilters([]);
    setGenreFilters([]);
  };

  const setRedirection = (fromPage: string | undefined): void => {
    setLastPage(fromPage || '/');
  };
  return (
    <FiltersContext.Provider
      value={{
        filters,
        clearFilters,
        sourceFilters,
        setSourceFilters,
        garmRiskLevelFilters,
        setGarmRiskLevelFilters,
        garmRiskLevelFiltersDisplay,
        setGarmRiskLevelFiltersDisplay,
        sentimentFilters,
        setSentimentFilters,
        sentimentFiltersDisplay,
        setSentimentFiltersDisplay,
        emotionFilters,
        setEmotionFilters,
        genreFilters,
        setGenreFilters,
        lastPage,
        setLastPage,
        setRedirection,

        setRiskFilter,
        setSentimentFilter,
        setEmotionFilter,
        setGenreFilter,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};
