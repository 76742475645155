import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import api, { Video } from 'api';
import { FiltersContext } from 'context/FiltersContext';
import Gallery from './Gallery';

const VIDEOS_PER_PAGE = 50;

const HomeGallery = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [videos, setVideos] = useState<Video[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [paginationKey, setPaginationKey] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  const {
    setRedirection,
  } = useContext(FiltersContext);

  useEffect(() => {
    setRedirection(location.pathname);
  }, []);

  const fetchRecentVideos = async (paginationKey = null) => {
    setIsLoading(true);
    try {
      const recentVideosResponse = await api.mbs.getRecentVideos(
        paginationKey,
        VIDEOS_PER_PAGE,
      );
      const newVideos = recentVideosResponse.body?.videos || [];
      setVideos((prevVideos) => {
        const videoSet = new Set(prevVideos.map((video) => video.video_id));
        const uniqueNewVideos = newVideos.filter((video) => !videoSet.has(video.video_id));
        return [...prevVideos, ...uniqueNewVideos];
      });
      setPaginationKey(recentVideosResponse.body.pagination_key);
      setHasMore(recentVideosResponse.body.pagination_key !== null);
    } catch (error) {
      console.error('Failed to fetch videos:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setVideos([]);
    fetchRecentVideos();
  }, []);

  const handleThumbnailClick = (content: Video) => {
    if (content.source === 'youtube') {
      navigate(
        `/content/${encodeURIComponent('https://www.youtube.com/watch?v=' + content.video_id)}`,
        {
          state: { content, creatorName: content.channel_name, contentTitle: content.video_title },
        },
      );
    } else if (content.source === 'tiktok') {
      navigate(`/content/${encodeURIComponent(content.video_url)}`, {
        state: { content, creatorName: content.channel_name, contentTitle: content.video_title },
      });
    } else if (content.source === 'xsocial') {
      navigate(`/content/${encodeURIComponent(content.post_url)}`, {
        state: {
          content,
          creatorName: content.channel_name,
          contentTitle: content.channel_nickname,
        },
      });
    } else if (content.source === 'article') {
      navigate(`/content/${encodeURIComponent(content.video_id)}`, {
        state: { content, creatorName: content.channel_id, contentTitle: content.article_title },
      });
    } else if (content.source === 'iris_tv') {
      navigate(`/content/${encodeURIComponent(content.video_id)}`, {
        state: { content, contentTitle: content.video_title },
      });
    }
  };
  const generateThumbnailNavigationObject = (content) => {
    if (content.source === 'youtube') {
      return {
        pathname: `/content/${encodeURIComponent('https://www.youtube.com/watch?v=' + content.video_id)}`,
        state: {
          content,
          creatorName: content.channel_name,
          contentTitle: content.video_title,
        },
      };
    } else if (content.source === 'tiktok') {
      return {
        pathname: `/content/${encodeURIComponent(content.video_url)}`,
        state: {
          content,
          creatorName: content.channel_name,
          contentTitle: content.video_title,
        },
      };
    } else if (content.source === 'xsocial') {
      return {
        pathname: `/content/${encodeURIComponent(content.post_url)}`,
        state: {
          content,
          creatorName: content.channel_name,
          contentTitle: content.channel_nickname,
        },
      };
    } else if (content.source === 'article') {
      return {
        pathname: `/content/${encodeURIComponent(content.video_id)}`,
        state: {
          content,
          creatorName: content.channel_id,
          contentTitle: content.article_title,
        },
      };
    } else if (content.source === 'iris_tv') {
      return {
        pathname: `/content/${encodeURIComponent(content.video_id)}`,
        state: { content, contentTitle: content.video_title },
      };
    }
  };

  useEffect(() => {
    if (isInitialLoad) {
      setIsInitialLoad(isLoading);
    }
  }, [isLoading]);

  return (
    <Gallery
      videos={videos}
      hasMore={hasMore}
      isLoading={isLoading}
      isInitialLoading={isInitialLoad}
      paginationKey={paginationKey}
      fetchVideos={fetchRecentVideos}
      handleThumbnailClick={handleThumbnailClick}
      generateThumbnailNavigationObject={generateThumbnailNavigationObject}
    />
  );
};

export default HomeGallery;
