import React, { useContext } from 'react';
import { Close } from '@mui/icons-material';
import { Button, InputBase } from '@mui/material';
import styled from '@emotion/styled';
import { useNavigate, useLocation } from 'react-router-dom';

import SearchFilterDropdown from './SearchFilterDropdown';
import { UrlContext } from '../context/UrlContext';
import { FiltersContext } from '../context/FiltersContext';

interface SearchBarProps {
  handleClearAllFilters: () => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ handleClearAllFilters }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { url, setUrl } = useContext(UrlContext);
  const { filters } = useContext(FiltersContext);

  const handleSubmit = () => {
    if (!url) {
      console.log('No valid url!');
      return;
    }
    navigate(`/video/${encodeURIComponent(url)}`, { state: { from: location.pathname } });
  };

  const handleEnterPress = (key) => {
    if (key === 'Enter') {
      handleSubmit();
    }
  };

  const handleClear = () => {
    setUrl('');
  };

  // Filters should only show on batch page or scorecards opened while on batch pages
  const isBatchPage = location.pathname.startsWith('/batch/');
  const isScorecardFromBatch = location.pathname.startsWith('/content/') && location.state?.from?.startsWith('/batch/');
  const showFilter = isBatchPage || isScorecardFromBatch;

  return (
    <SearchBarContainer>
      <SearchRowContainer>
        <SearchInputContainer>
          <CloseIconWrapper>
            {url && url.trim() !== '' && <Close onClick={handleClear} />}
          </CloseIconWrapper>
          <StyledInputBase
            placeholder="Enter URL…"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            onKeyDown={(e) => handleEnterPress(e.key)}
            inputProps={{ 'aria-label': 'search' }}
          />
        </SearchInputContainer>
        <Button
          variant="contained"
          size="small"
          onClick={handleSubmit}
          sx={{
            backgroundColor: '#263238',
            textTransform: 'capitalize',
            '&:hover': {
              backgroundColor: '#263238',
            },
          }}
        >
          Submit
        </Button>
      </SearchRowContainer>
      {showFilter && (
        <FilterRowContainer>
          {filters.map((f) => (
            <SearchFilterDropdown key={f.title} {...f} />
          ))}
          <ClearButton onClick={handleClearAllFilters}>Clear</ClearButton>
        </FilterRowContainer>
      )}
    </SearchBarContainer>
  );
};

export default SearchBar;

const SearchBarContainer = styled.div`
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 0 1em 1em 1em;
`;

const SearchRowContainer = styled.div`
  display: flex;
  gap: 6px;
  min-height: 40px;
`;

const FilterRowContainer = styled.div`
  display: flex;
  gap: 6px;
  flex-wrap: nowrap;
  flex-shrink: 1;
  & > * {
    flex: 1 1 auto;
  }
`;

const SearchInputContainer = styled.div`
  position: relative;
  width: 100%;
`;

const CloseIconWrapper = styled.div`
  color: #37474f;
  position: absolute;
  right: 8px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 999;
`;

const StyledInputBase = styled(InputBase)`
  color: #263238;
  background-color: white;
  width: 100%;
  border: 2px solid #ddd;
  border-radius: 6px;
  padding-right: 1.5em;
  & .MuiInputBase-input {
    padding: 8px;
    font-weight: 400;
    color: gray;
    opacity: 1;
  }
`;

const ClearButton = styled(Button)`
  background-color: white;
  box-shadow: none;
  color: #263238;
  min-width: 10%;
  max-width: 10%;
  text-wrap: wrap;
  font-size: 14px;
  line-height: 1.25;
  font-weight: bold;
  text-transform: capitalize;
  &:hover {
    background-color: white;
    box-shadow: none;
  }
  @media (max-width: 650px) {
    font-size: 10px;
  }
`;
