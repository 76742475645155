import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { makeInitialState } from './state';

export default function useVideoInfo() {
  const location = useLocation();
  const [state, _setState] = useState(makeInitialState(location));

  const setState = (newState) => {
    _setState((prevState) => ({ ...prevState, ...newState }));
  };

  const notUndefinedReducer = (acc, [key, value]) => {
    if (value === undefined) return acc;
    return { ...acc, [key]: value };
  };

  const truthyReducer = (acc, [key, value]) => {
    if (!value) return acc;
    return { ...acc, [key]: value };
  };

  const setStateIfTruthy = (newState) => {
    const updatedState = Object.entries(newState).reduce(truthyReducer, {});
    setState(updatedState);
  };

  const setIfNotUndefined = (newState) => {
    const updatedState = Object.entries(newState).reduce(notUndefinedReducer, {});
    setState(updatedState);
  };

  return { state, setState, setStateIfTruthy, setIfNotUndefined };
}
